body {
   transition: all 0.3s;
}

.navBrandIcon {
   height: 20px;
}

.backgroundBanner {
   background-image: linear-gradient(
         to left,
         rgba(255, 255, 255, 0.219),
         rgba(0, 0, 0, 0.73)
      ),
      url("../src/images/backg.jpg");

   background-repeat: no-repeat;
   background-position: center;
   background-size: cover;
   background-attachment: fixed;
   min-height: 100vh;
}
.bannerHeading {
   color: white;
}
.light-text {
   font-weight: 200;
}
.headingText {
   font-weight: 400;
   font-size: clamp(1.5625rem, 1.3657rem + 1.0494vw, 2.625rem);
}
.emphasizeSolar {
   color: rgb(252, 187, 58);
   font-weight: 800;
   font-size: clamp(1.875rem, 1.6435rem + 1.2346vw, 3.125rem);
   text-decoration: underline;
}
.emphasize {
   color: rgb(252, 187, 58);
   text-decoration: underline;
}

.image-shadow {
   box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.75);
   -webkit-box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.75);
   -moz-box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.75);
}

.min-80vh {
   min-height: 80vh;
}
.accomplishmentIcons {
   opacity: 0.4;
   height: 150px;
   width: 150px;
   border-radius: 50%;
   box-shadow: -1px 1px 16px 0px rgba(0, 0, 0, 0.75);
   -webkit-box-shadow: -1px 1px 16px 0px rgba(0, 0, 0, 0.75);
   -moz-box-shadow: -1px 1px 16px 0px rgba(0, 0, 0, 0.75);
}

.accomplishmentBackground {
   clip-path: polygon(
      29% 0,
      70% 4%,
      100% 0,
      100% 100%,
      74% 97%,
      33% 96%,
      0 100%,
      0 6%
   );
   text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.32);

   background-color: rgba(58, 147, 248, 0.082);
   border-radius: 100px -100px 100px 0;
   min-height: 40vh;
   box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.79);
   -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.79);
   -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.79);
}

.card-img-top {
   position: relative;
}

.projectImages {
   height: 450px;
   width: 100%;
   object-fit: cover;
   transition: all 0.5s ease-in-out;
}

.readMore {
   display: none;
   position: absolute;
   cursor: pointer;
   color: rgb(255, 255, 255);
   transition: all 0.5s ease !important;
   z-index: 10;
}

.card-img-top:hover .readMore {
   display: block;
   transition: all 0.5s ease !important;
}
.card-img-top:hover .projectImages {
   filter: brightness(50%);
}

.loaderPage {
   min-height: 80vh;
}

.servicesIcons {
   height: 75px;
}
.card-body-services {
   transition: all 0.3s ease;
   border-radius: 10px;
   min-height: 350px;
   box-shadow: 0px 14px 13px -6px rgba(0, 0, 0, 0.33);
   -webkit-box-shadow: 0px 14px 13px -6px rgba(0, 0, 0, 0.33);
   -moz-box-shadow: 0px 14px 13px -6px rgba(0, 0, 0, 0.33);
}

.card-body-services:hover {
   background-color: rgb(252, 187, 58);
}

.servicesText {
   transition: all 0.3s ease;
}

.card-body-services:hover .servicesText {
   color: white;
   transform: translateY(-5px);
}

.servicesIcons {
   transition: all 0.3s ease;
}

.card-body-services:hover .servicesIcons {
   filter: invert();
   animation: shake 1.5s;
   animation-iteration-count: infinite;
}

@keyframes shake {
   0% {
      transform: translate(1px, 1px) rotate(0deg);
   }
   10% {
      transform: translate(-1px, -2px) rotate(-1deg);
   }
   20% {
      transform: translate(-3px, 0px) rotate(1deg);
   }
   30% {
      transform: translate(3px, 2px) rotate(0deg);
   }
   40% {
      transform: translate(1px, -1px) rotate(1deg);
   }
   50% {
      transform: translate(-1px, 2px) rotate(-1deg);
   }
   60% {
      transform: translate(-3px, 1px) rotate(0deg);
   }
   70% {
      transform: translate(3px, 1px) rotate(-1deg);
   }
   80% {
      transform: translate(-1px, -1px) rotate(1deg);
   }
   90% {
      transform: translate(1px, 2px) rotate(0deg);
   }
   100% {
      transform: translate(1px, -2px) rotate(-1deg);
   }
}

.text-light-weight {
   font-weight: 300;
}

.footer {
   position: relative;
}
.footer-container {
   background-color: rgb(6, 4, 41);
   min-height: 30vh;
   color: rgb(224, 224, 224);
}

.text-underline {
   text-decoration: underline;
}
.color-orange {
   color: rgb(252, 187, 58);
   transition: all 0.5s ease;
}
.color-orange:hover {
   color: rgb(139, 112, 58);
}
.listUnstyled {
   list-style: none;
}

.blueButton {
   background-color: rgb(6, 4, 41) !important;
   transition: all 0.3s;
}
.blueButton:hover {
   background-color: rgb(119, 80, 1) !important;
   color: rgb(255, 255, 255);
}
#contact {
   background-color: rgb(252, 187, 58);
   color: rgb(6, 4, 41);
   border-radius: 10px;
}
.services-footer-lists {
   list-style: circle;
}
.loginContainer {
   min-height: 80vh;
}
.min-100vh {
   min-height: 100vh;
}
.notFoundHeading {
   font-size: 10rem;
}

.underline {
   border-top: 3px solid rgb(250, 174, 21);
   width: 100px;
}
.underline2 {
   border-top: 4px solid rgb(250, 174, 21);
   width: 150px;
}
#projects {
   letter-spacing: 5px;
}

.buttonOutline:hover {
   background-color: rgb(252, 187, 58);
   color: white;
}
.letterSpacing {
   letter-spacing: 1.5px;
}
.backgroundFrame {
   position: absolute;
   height: 400px;
   width: 100%;
   left: -35px;
   bottom: -45px;

   border: 25px solid rgba(250, 174, 21, 0.199);

   z-index: -1;
}

.loaderImage {
   height: 15px;
   filter: invert();
}

.cardIcons {
   transition: all 0.1s;
}
.cardIcons:hover {
   transform: scale(1.1);
   text-shadow: 0px 1px 4px rgba(80, 80, 80, 0.3);
   animation: shake2 3s;
   animation-iteration-count: infinite;
}
@keyframes shake2 {
   0% {
      transform: translate(1px, 1px) rotate(0deg);
   }
   10% {
      transform: translate(-1px, -2px) rotate(-1deg);
   }
   20% {
      transform: translate(-2px, 0px) rotate(1deg);
   }
   30% {
      transform: translate(2px, 1px) rotate(0deg);
   }
   40% {
      transform: translate(1px, -1px) rotate(1deg);
   }
   50% {
      transform: translate(-1px, 2px) rotate(-1deg);
   }
   60% {
      transform: translate(-2px, 1px) rotate(0deg);
   }
   70% {
      transform: translate(2px, 1px) rotate(-1deg);
   }
   80% {
      transform: translate(-1px, -1px) rotate(1deg);
   }
   90% {
      transform: translate(1px, 2px) rotate(0deg);
   }
   100% {
      transform: translate(1px, -2px) rotate(-1deg);
   }
}

.cardIcons:active {
   transform: translateY(3px);
}

.footerText {
   color: rgb(153, 153, 153);
}
